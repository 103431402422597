import { MenuRootItem } from 'ontimize-web-ngx';

export const MENU_CONFIG: MenuRootItem[] = [
  { id: 'init', name: 'MENU.INIT', icon: 'home', route: '/main/home' },
  { id: 'csvs', name: 'MENU.CSVS', icon: 'task', route: '/main/csvs' },
  {
    id: 'organization', name: 'MENU.ORGANIZATION', icon: 'list',
    opened: false,
    items: [
      { id: 'users', name: 'MENU.USERS', icon: 'person', route: '/main/organization/users' },
      { id: 'roles', name: 'MENU.ROLES', icon: 'group', route: '/main/organization/roles' },
      { id: 'doctors', name: 'MENU.DOCTORS', icon: 'medical_information', route: '/main/organization/doctors' },
      { id: 'teams', name: 'MENU.TEAMS', icon: 'groups', route: '/main/organization/teams' },
      { id: 'businessdivisions', name: 'MENU.BUSINESS_DIVISIONS', icon: 'work', route: '/main/organization/businessdivisions' },
      { id: 'businesslines', name: 'MENU.BUSINESS_LINES', icon: 'attach_money', route: '/main/organization/businesslines' }
    ]
  },
  {
    id: 'samples', name: 'MENU.SAMPLES', icon: 'science',
    opened: false,
    items: [
      { id: 'products', name: 'MENU.PRODUCTS', icon: 'science', route: '/main/samples/products' },
      { id: 'samplesdataconfiguration', name: 'MENU.SAMPLES_DOC_MANAGEMENT', icon: 'manage_search', route: '/main/samples/dataconfiguration' }
    ]
  },
  {
    id: 'mailing', name: 'MENU.MAILING', icon: 'mail',
    opened: false,
    items: [
      { id: 'mailingtemplate', name: 'MENU.MAILING_TEMPLATE', icon: 'content_copy', route: '/main/mail/template' },
      { id: 'mailingmanagement', name: 'MENU.MAILING_MANAGEMENT', icon: 'settings', route: '/main/mail/management' },
      // { id: 'mailingnotifications', name: 'MENU.MAILING_NOTIFICATIONS', icon: 'notifications', route: '/main/mail/notifications' },
      // { id: 'mailingreport', name: 'MENU.MAILING_REPORT', icon: 'summarize', route: '/main/mail/report' }
    ]
  },
  {
    id: 'documents', name: 'MENU.DOCUMENTS', icon: 'description',
    opened: true,
    items: [
      { id: 'documentspending', name: 'MENU.DOCUMENTS_PENDING', icon: 'pending_actions', route: '/main/documents/pending' },
      { id: 'documentsfinished', name: 'MENU.DOCUMENTS_FINISHED', icon: 'task', route: '/main/documents/finished' },
      { id: 'template', name: 'MENU.DOCUMENTS_TEMPLATE', icon: 'task', route: '/main/documents/template' },
      { id: 'newdocument', name: 'MENU.DOCUMENTS_PRODCUTS_NEW', icon: 'science', route: '/main/documents/new' },
      { id: 'efpianew', name: 'MENU.DOCUMENTS_EFPIA_NEW', icon: 'note_add', route: '/main/documents/efpianew' },
      // { id: 'activityregister', name: 'MENU.DOCUMENTS_ACTIVITY_REGISTER', icon: 'edit', route: '/main/documents/activityregister' }
    ]
  },
  // {
  //   id: 'other', name: 'MENU.OTHER', icon: 'task',
  //   opened: false,
  //   items: [
  //     { id: 'othercheckpoint', name: 'MENU.OTHER_CHECKPOINT', icon: 'mail', route: '/main/checkpoint' }
  //   ]
  // }
];